<template>
  <div class="center">
    <b-modal id="AddLevel" hide-footer no-close-on-backdrop content-class="shadow" title="Add Level" ok-title="Accept">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label>Level Name</label>
                <validation-provider #default="{ errors }" name="Nama Level" rules="required">
                  <b-form-input v-model="add.level_name" :state="errors.length > 0 ? false:null"
                    placeholder="Level Name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>Icon</label>
                <validation-provider #default="{ errors }" name="icon" rules="required">
                  <b-form-input v-model="add.icon" :state="errors.length > 0 ? false:null" type="text"
                    placeholder="Icon Font Aweshome" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <label class="m-0">Status</label>
              <div class="demo-inline-spacing">
                <b-form-radio v-model="add.st" name="add_st" value="1">
                  Enable
                </b-form-radio>
                <b-form-radio v-model="add.st" name="add_st" value="0">
                  Disable
                </b-form-radio>
              </div>
            </b-col>
            <b-col cols="12" class="mt-1">
              <label>Induk</label>
              <b-form-group> 
                <validation-provider #default="{ errors }" name="Level" rules="required">
                  <v-select rules="required" :state="errors.length > 0 ? false:null" v-model="add.induk"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="induk" :options="IndukLevel" />
                </validation-provider>
              </b-form-group>
            </b-col>
            <hr>
            <b-col cols="6">
              <b-button class="mb-3 mt-1" variant="outline-primary" block type="submit" @click.prevent="validationForm">
                <i class="fa fa-save mr-1"></i>Simpan
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button class="mb-3 mt-1" variant="outline-warning" block @click="$bvModal.hide('AddLevel')">
                <i class="fa fa-close mr-1"></i> Keluar</b-button>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>



    </b-modal>
  </div>
</template>
<script>
  import axios from '@/config/Axios';
  import Base from '@/config/Mixins_base';

  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
  } from '@validations'
  import {
    BModal,
    BButton,
    VBModal,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BCardText,
    BFormRadio,

  } from 'bootstrap-vue'
  import vSelect from 'vue-select'

  export default {
    props: {
      IndukLevel: {}
    },
    data: () => ({ 
      required,
      add: {}

    }),
    mixins: [Base],
    emits: ["LoadLevel"],
    components: {
      BModal,
      BButton,
      VBModal,
      ValidationProvider,
      ValidationObserver,
      BFormInput,
      BFormGroup,
      BForm,
      BRow,
      BCol,
      BCardText,
      vSelect,
      BFormRadio,
    },
    methods: {
      async act_add() {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/acl/level/insert',
            data: {
              induk: self.add.induk.induk,
              level_name: self.add.level_name,
              ket: self.add.ket,
              st: self.add.st,
              icon: self.add.icon
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) { 
            self.notification('primary', "info", response.data.message);
            self.$emit("LoadLevel", "");
            self.add = {};
          }).catch(err => {
            self.notification('warning', "Error", err.message);
          });
      },

      validationForm() {

        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$root.$emit('bv::hide::modal', 'AddLevel')
            this.act_add(); 
          }
        })
      },
    },

  }
</script>