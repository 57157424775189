<template>
  <div class="center">
    <b-modal id="KelaloAkses" size="lg" hide-footer no-close-on-backdrop content-class="shadow" title="Add Level" ok-title="Accept">
      <div v-for="kelolaLevel in kelolaLevel" v-bind:key="kelolaLevel.menu_key">
              <div class="row text-muted">
                <div class="col-sm-7">
                  <h6 class="text-muted"><i class="fa fa-list mr-1"></i> {{kelolaLevel.nm_menu}}</h6>
                </div>
                <div class="col-sm-1" v-if="kelolaLevel.akses_c =='1'">
                  <div class="badge badge-pill badge-primary"
                    @click="menu_akses(LeveData.id_level,kelolaLevel.menu_key, 'menu' ,'0', kelolaLevel.akses_r ,kelolaLevel.akses_u ,kelolaLevel.akses_d)">
                    <i class="fa fa-check mr-1"></i>C </div>
                </div>
                <div class="col-sm-1" v-if="kelolaLevel.akses_c =='0'"
                  @click="menu_akses(LeveData.id_level,kelolaLevel.menu_key, 'menu' ,'1', kelolaLevel.akses_r ,kelolaLevel.akses_u ,kelolaLevel.akses_d)">
                  <div class="badge badge-pill badge-warning"><i class="fa fa-warning mr-1"></i>C </div>
                </div>
                <div class="col-sm-1" v-if="kelolaLevel.akses_r =='1'">
                  <div class="badge badge-pill badge-primary"
                    @click="menu_akses(LeveData.id_level,kelolaLevel.menu_key, 'menu' , kelolaLevel.akses_c, '0' ,kelolaLevel.akses_u ,kelolaLevel.akses_d)">
                    <i class="fa fa-check mr-1"></i>R</div>
                </div>
                <div class="col-sm-1" v-if="kelolaLevel.akses_r =='0'">
                  <div class="badge badge-pill badge-warning"
                    @click="menu_akses(LeveData.id_level,kelolaLevel.menu_key, 'menu' , kelolaLevel.akses_c, '1' ,kelolaLevel.akses_u ,kelolaLevel.akses_d)">
                    <i class="fa fa-warning mr-1"></i>R</div>
                </div>
                <div class="col-sm-1" v-if="kelolaLevel.akses_u =='1'">
                  <div class="badge badge-pill badge-primary"
                    @click="menu_akses(LeveData.id_level,kelolaLevel.menu_key, 'menu' , kelolaLevel.akses_c, kelolaLevel.akses_r, '0' ,kelolaLevel.akses_d)">
                    <i class="fa fa-check mr-1"></i>U </div>
                </div>
                <div class="col-sm-1" v-if="kelolaLevel.akses_u =='0'">
                  <div class="badge badge-pill badge-warning"
                    @click="menu_akses(LeveData.id_level,kelolaLevel.menu_key, 'menu' , kelolaLevel.akses_c, kelolaLevel.akses_r, '1' ,kelolaLevel.akses_d)">
                    <i class="fa fa-warning mr-1"></i>U </div>
                </div>
                <div class="col-sm-1" v-if="kelolaLevel.akses_d =='1'">
                  <div class="badge badge-pill badge-primary pr-1"
                    @click="menu_akses(LeveData.id_level,kelolaLevel.menu_key, 'menu' , kelolaLevel.akses_c, kelolaLevel.akses_r, kelolaLevel.akses_u, '0')">
                    <i class="fa fa-check mr-1"></i>D </div>
                </div>
                <div class="col-sm-1" v-if="kelolaLevel.akses_d =='0'">
                  <div class="badge badge-pill badge-warning pr-1"
                    @click="menu_akses(LeveData.id_level,kelolaLevel.menu_key, 'menu' , kelolaLevel.akses_c, kelolaLevel.akses_r, kelolaLevel.akses_u, '1')">
                    <i class="fa fa-warning mr-1"></i>D </div>
                </div>
              </div>  
               <div>
                <div class="row text-muted" v-for="menu_sub in kelolaLevel.menu_sub" v-bind:key="menu_sub.menu_key">
                  <div class="col-sm-7 ">
                    <h6 class="text-muted ml-2"><i class="fa fa-list mr-1"></i> {{menu_sub.nm_menu}}</h6>
                  </div>
                  <div class="col-sm-1  " v-if="menu_sub.akses_c =='1'">
                    <div class="badge badge-pill badge-info"
                    @click="menu_akses(LeveData.id_level,menu_sub.menu_key, 'sub_menu' , '0', menu_sub.akses_r, menu_sub.akses_u, menu_sub.akses_d)">
                   <i class="fa fa-check mr-1"></i>C </div>
                  </div>
                  <div class="col-sm-1" v-if="menu_sub.akses_c =='0'">
                    <div class="badge badge-pill badge-danger" 
                     @click="menu_akses(LeveData.id_level,menu_sub.menu_key, 'sub_menu' , '1', menu_sub.akses_r, menu_sub.akses_u, menu_sub.akses_d)">
                  <i class="fa fa-warning mr-1"></i>C </div>
                  </div>
  
                  <div class="col-sm-1" v-if="menu_sub.akses_r =='1'">
                    <div class="badge badge-pill badge-info" 
                     @click="menu_akses(LeveData.id_level,menu_sub.menu_key, 'sub_menu' ,   menu_sub.akses_c,'0', menu_sub.akses_u, menu_sub.akses_d)">
                     <i class="fa fa-check mr-1"></i>R</div>
                  </div>
                  <div class="col-sm-1" v-if="menu_sub.akses_r =='0'">
                    <div class="badge badge-pill badge-danger"                   
                    @click="menu_akses(LeveData.id_level,menu_sub.menu_key, 'sub_menu' ,   menu_sub.akses_c,'1', menu_sub.akses_u, menu_sub.akses_d)">
                    <i class="fa fa-warning mr-1"></i>R</div>
                  </div>
                  <div class="col-sm-1" v-if="menu_sub.akses_u =='1'">
                    <div class="badge badge-pill badge-info"
                    @click="menu_akses(LeveData.id_level,menu_sub.menu_key, 'sub_menu' ,   menu_sub.akses_c,  menu_sub.akses_r,'0', menu_sub.akses_d)">
                    <i class="fa fa-check mr-1"></i>U </div>
                  </div>
                  <div class="col-sm-1" v-if="menu_sub.akses_u =='0'">
                    <div class="badge badge-pill badge-danger"
                    @click="menu_akses(LeveData.id_level,menu_sub.menu_key, 'sub_menu' ,   menu_sub.akses_c,  menu_sub.akses_r,'1', menu_sub.akses_d)">
                    <i class="fa fa-warning mr-1"></i>U </div>
                  </div>
                  <div class="col-sm-1" v-if="menu_sub.akses_d =='1'">
                    <div class="badge badge-pill badge-info pr-1" 
                    @click="menu_akses(LeveData.id_level,menu_sub.menu_key, 'sub_menu' ,   menu_sub.akses_c,  menu_sub.akses_r,  menu_sub.akses_u, '0')">
                   <i class="fa fa-check mr-1"></i>D </div>
                  </div>
                  <div class="col-sm-1" v-if="menu_sub.akses_d =='0'">
                    <div class="badge badge-pill badge-danger pr-1"
                    @click="menu_akses(LeveData.id_level,menu_sub.menu_key, 'sub_menu' ,   menu_sub.akses_c,  menu_sub.akses_r,  menu_sub.akses_u, '1')">
                  <i class="fa fa-warning mr-1"></i>D </div>
                  </div>
                </div>
              </div> 
            </div>
    </b-modal>
  </div>
</template>
<script>
  import axios from '@/config/Axios';
  import Base from '@/config/Mixins_base';
  import {
      mapMutations,
      mapState
    } from 'vuex'
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
  } from '@validations'
  import {
    BModal,
    BButton,
    VBModal,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BCardText,
    BFormRadio,

  } from 'bootstrap-vue'
  import vSelect from 'vue-select'

  export default {
    props: {
      LeveData: {}
    },
    data: () => ({ 
      required,
      add: {}

    }),
    mixins: [Base],
    emits: ["LoadLevel"],
    components: {
      BModal,
      BButton,
      VBModal,
      ValidationProvider,
      ValidationObserver,
      BFormInput,
      BFormGroup,
      BForm,
      BRow,
      BCol,
      BCardText,
      vSelect,
      BFormRadio,
    },
    computed: {
        ...mapState({
          kelolaLevel: state => state.aclLevel.kelolaLevel,
        })
      },
    methods: { 
      
      ...mapMutations({
          setKelolaLevel: 'setKelolaLevel',
        }),
        async menu_akses(id_level, menu_key, st, c, r, u, d) {
            const self = this;
            await axios({
              method: 'POST',
              url: '/api/acl/level_akses/menu_akses',
              data: {
                level_key: id_level,
                menu_key: menu_key,
                st: st,
                akses_c: c,
                akses_r: r,
                akses_u: u,
                akses_d: d,
              },
              headers: {
                'Authorization': self.isAuthenticated
              }
              })
              .then(function ( ) {
                 
              self.KelolaAkses(id_level); 
        
              }).catch(err => {
              self.pesan = err.message;
              self.notification('warning', "Error", err.message);
              });
            },
   
  
        KelolaAkses(id) {
          const self = this; 
          axios({
              method: 'PUT',
              url: '/api/acl/level_akses/menu',
              data: {
                id_level: id,
              },
              headers: {
                'Authorization': self.isAuthenticated
              }
            })
            .then(function (response) {
              self.setKelolaLevel(response.data.result);
            }).catch(err => {
              self.pesan = err.message;
              self.notification('warning', "Error", err.message);
            });
        },
        close() {
          // this.$emit("LoadLevel", "");
          this.$root.$emit('bv::hide::modal', 'KelaloAkses')
        },
    },

  }
</script>