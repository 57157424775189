<template>
    <div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12" v-for="rsLevel in rsLevel" v-bind:key="rsLevel.id_level">
                <div class="card card-apply-job">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                            <div class="media">
                                <div class="media-body">
                                    <h4><i :class="'fa ' + rsLevel.icon + ' mr-1'"></i>{{rsLevel.level_name}}</h4>
                                    <small class="text-muted">{{rsLevel.coun_user}} Users Aktiv</small>
                                </div>
                            </div>
                            <div class="badge d-block badge-primary" v-if="rsLevel.st =='1'"
                                @click="DisableEnable(rsLevel.id_level)">
                                <span><i class="fa fa-check mr-1"></i>Enable</span>
                            </div>
                            <div class="badge d-block badge-danger" v-if="rsLevel.st !='1'"
                                @click="DisableEnable(rsLevel.id_level)">
                                <span><i class="fa fa-warning mr-1"></i>Disable</span>
                            </div>
                        </div>
                        <p class="card-text mb-2" v-if=" rsLevel.ket != null ">
                            {{rsLevel.ket}}
                        </p>
                        <p class="card-text mb-2" v-if=" rsLevel.ket == null ">
                            -
                        </p>
                        <div class="apply-job-package bg-light-primary rounded">
                            <div>
                                <h2 class="d-inline mr-25 mr-1">{{rsLevel.jm_menu}}/{{rsLevel.jm_sub_menu}}</h2>
                                <sub class="text-body"><small>Menu/Sub Menu</small></sub>
                            </div>
                            <div class="btn btn-primary  btn-sm" block  
                                @click="showMenuList(rsLevel)" v-b-modal.MenuList><i class="fa fa-list"></i> Menu List
                            </div>
                        </div>
                        <div class="row  ">
                            <div class="col-lg-6 mb-1">
                                <button type="button" block  
                                    @click="EditLevel(rsLevel)" v-b-modal.EditLevel
                                    class="btn btn-outline-warning btn-block waves-effect"><i
                                        class="fa fa-edit mr-1"></i>Edit</button>
                            </div>
                            <div class="col-lg-6 mb-1">
                                <button type="button" class="btn btn-outline-danger btn-block waves-effect"
                                    @click="DeleteLevel(rsLevel.id_level)"><i
                                        class="fa fa-trash mr-1"></i>Delete</button>
                            </div>
                        </div>

                        <button type="button" block   v-b-modal.KelaloAkses 
                        @click="KelolaAkses(rsLevel.id_level, rsLevel)"
                            class="btn btn-primary btn-block waves-effect waves-float waves-light"><i
                                class="fa fa-gears mr-1"></i>Kelola Level Akses</button>
                    </div>
                </div>
            </div>
        </div>
        <MenuList :MenuListData=MenuListData></MenuList>
        <CardEditLevel @LoadLevel = LoadLevel  :LeveData = LeveData :IndukLevel = IndukLevel></CardEditLevel>
        <KelaloAkses :LeveData = LeveData></KelaloAkses>
    </div>
</template>

<script>
    import MenuList from './MenuList.vue'

    import CardEditLevel from './EditLevel.vue'
    import KelaloAkses from './KelaloAkses.vue'
    import {
        mapMutations,
        mapState
    } from 'vuex'
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';

    import Ripple from 'vue-ripple-directive'
    import {
        BModal,
        BButton,
        VBModal,
        BPagination

    } from 'bootstrap-vue'
    export default {
        data() {
            return {
                id_level : {},
                selected: 'default',
                currentPage: 1,
                rows: 100,
                MenuListData: {},
                LeveData : {}
            }
        },
        props:{
            IndukLevel: {}
        },
        mixins: [Base],
        emit : ['LoadLevelData'],
        computed: {
            ...mapState({
                rsLevel: state => state.aclLevel.rsLevel,
                kelolaLevel: state => state.aclLevel.kelolaLevel,
            })
        },

        watch: {
            currentPage(newData, old) {
                if (newData != NaN) {
                    this.loadData();
                }
            }
        },

        components: {
            BPagination,
            BModal,
            BButton,
            VBModal,
            MenuList,
            CardEditLevel,
            KelaloAkses

        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        methods: {
            ...mapMutations({
                setRsLevel: 'setRsLevel',
                setKelolaLevel : 'setKelolaLevel'
                
            }),
            showMenuList(val) {
                this.MenuListData = val;
            },
            KelolaAkses(id, rsLevel ){ 
                this.LeveData = rsLevel
                const self = this;
                axios({
                        method: 'PUT',
                        url: '/api/acl/level_akses/menu',
                        data: {
                            id_level: id,
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) { 
                        self.setKelolaLevel(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            DisableEnable(id) {
                const self = this;
                axios({
                        method: 'PUT',
                        url: '/api/acl/level/disable',
                        data: {
                            id_level: id,
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.notification('warning', "info", response.data.message);
                        self.loadData();
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            LoadLevel (){
                this.loadData();
                this.$emit("LoadLevelData", "");
            },  
            async loadData() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/acl/level/list',
                        data: {
                            offset: self.currentPage,
                            cari: self.cari
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.setRsLevel(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async DeleteLevel(key) {
                const self = this;
                if (confirm('Apakah anda yakin akan hapus level ini..?')) {
                    await axios({
                            method: 'POSt',
                            url: '/api/acl/level/delete',
                            data: {
                                id_level: key
                            },
                            headers: {
                                'Authorization': self.isAuthenticated
                            }
                        })
                        .then(function (response) {
                            self.notification('warning', "info", response.data.message);
                            self.loadData(); 
                        }).catch(err => {
                            self.pesan = err.message;
                            self.notification('warning', "Error", err.message);
                        });
                }
            },
            EditLevel(val){
                this.LeveData = val
            }
        }
    }
</script>

<style>

</style>