<template>
  <div class="center">

    <b-modal id="MenuList" hide-footer no-close-on-backdrop content-class="shadow" title="Menu List" ok-title="Accept"> 
      <ol>
        <li v-for="menu in MenuListData.menu " v-bind:key="menu.nm_menu">
          <div class="row">
            <div class="col-md-8">
              <i :class="'fa ' + menu.icon + ' mr-1'"></i>{{menu.nm_menu}}
            </div>
            <div class="col-md-4 float-right">
              <div class="float-right">
                <div class="badge badge-light-secondary" v-if="menu.c =='1'">C</div>
                <div class="badge badge-light-primary" v-if="menu.r =='1'">R</div>
                <div class="badge badge-light-success" v-if="menu.u =='1'">U</div>
                <div class="badge badge-light-danger" v-if="menu.d =='1'">D</div>
              </div>
            </div>
          </div>


          <ol>
            <li v-for="sub_menu in menu.sub " v-bind:key="sub_menu.nm_menu"> 
              <div class="row">
            <div class="col-md-8">
              {{sub_menu.nm_menu}}
            </div>
            <div class="col-md-4 float-right">
              <div class="float-right">
                <div class="badge badge-light-secondary" v-if="sub_menu.c =='1'">C</div>
                <div class="badge badge-light-primary" v-if="sub_menu.r =='1'">R</div>
                <div class="badge badge-light-success" v-if="sub_menu.u =='1'">U</div>
                <div class="badge badge-light-danger" v-if="sub_menu.d =='1'">D</div>
              </div>
            </div>
          </div>
          
          
          </li>

          </ol>

        </li>

      </ol>
    </b-modal>
  </div>
</template>
<script>
  import {
    BModal,
    BButton,
    VBModal,
    BRow,
    BCol,

  } from 'bootstrap-vue'
  import vSelect from 'vue-select'

  export default {
    props: {
      MenuListData: {}
    },
    data: () => ({


    }),
    components: {
      BModal,
      BButton,
      VBModal,
      BRow,
      BCol,
      vSelect,
    },
    methods: {
      closeMenu() {
        this.$root.$emit('bv::hide::modal', 'MenuList')
      },
    },

  }
</script>